@font-face {
    font-family: 'Fakt Medium';
    src: local('Fatk Medium'), url(./fonts/fakt-med.ttf) format('truetype');
}

@font-face {
    font-family: 'Fakt Normal';
    src: local('Fakt Normal'), url(./fonts/fakt-nor.ttf) format('truetype');
}

@font-face {
    font-family: 'Fakt SemiBold';
    src: local('Fakt SemiBold'), url(./fonts/fakt-smbd.ttf) format('truetype');
}

p{
    margin: 0;
}

body{
    overflow-x: hidden;
    overflow-y: hidden;
}

h2{
    margin: 0;
}