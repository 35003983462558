.error-feedback {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: rgba(255, 99, 71, 0.1); /* Light red background */
    border: 1px solid #ff6347; /* Red border */
    border-radius: 5px;
    animation: fadeIn 0.5s ease-in-out;
    margin-top: 10px;
}

.error-message {
    display: flex;
    align-items: center;
    color: #ff6347; /* Red text */
    font-size: 16px;
    font-weight: bold;
}

.error-icon {
    margin-right: 10px;
    font-size: 20px;
    animation: bounce 1s infinite; /* Animação de pulo no ícone */
}

.close-btn {
    background: none;
    border: none;
    color: #ff6347;
    font-size: 20px;
    cursor: pointer;
    margin-left: 20px;
}

.close-btn:hover {
    color: #ff4500;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Animação de pulo (bounce) para o ícone */
@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(-10px); /* Move para cima */
    }
    50% {
        transform: translateY(0); /* Volta à posição inicial */
    }
    75% {
        transform: translateY(-5px); /* Move um pouco para cima novamente */
    }
}

.contracts-page-container{
    /* border: 1px solid #DEDEDE; */
    margin: 15px;
    margin-top: 81px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.contracts-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    border-bottom: 1px solid #DEDEDE;
}

.contracts-top-left{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.contracts-top-right{
    display: flex;
    flex-direction: row;
    margin-right: 5%;
    align-items: center;
    justify-content: flex-end;
}

.contracts-middle{
    height: 70px;
}

.contracts-bottom{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
}

.contracts-bottom-title{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 50px;
    background-color: #EFEFEF;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.contract-titles{
    display: flex;
    align-items: center;
    color: #777777;
    font-family: 'Fakt Normal';
    font-weight: bold;
}

.title-number{
    width: 5%;
}

.title-title{
    width: 25%;
}

.title-people{
    width: 10%;
}

.title-status{
    width: 10%;
}

.title-created{
    width: 20%;
}

.title-delete{
    width: 10%;
    display: flex;
    justify-content: center;
}

.contracts-bottom-contracts{
    display: flex;
    flex-direction: column;
    height: 64vh;
}

.contracts-bottom-contract{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-height: 55px;
    border-bottom: 1px solid #DEDEDE;
    cursor: pointer;
    transition: 0.3s;
}

.contracts-bottom-contract:hover{
    box-shadow: rgba(60, 64, 67, 0.3) 1px 1px 2px 0px, rgba(60, 64, 67, 0.15) 1px 2px 6px 2px;
}

.contract-contents{
    display: flex;
    align-items: center;
    color: #777777;
    text-decoration: none;
}

.contract-number{
    width: 5%;
}

.contract-title{
    width: 25%;
}

.contract-people{
    width: 10%;
}

.contract-status{
    width: 10%;
}

.contract-created{
    width: 20%;
}

.contract-delete{
    width: 10%;
    display: flex;
    justify-content: center;
}

.contracts-new-contract{
    border-width: 0;
    height: 50px;
    width: 160px;
    background-color: #03a9f4;
    color: white;
    font-size: 16px;
    font-family: 'Fakt Medium';
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s;
}

.contracts-new-contract:hover{
    background-color: rgb(32, 185, 255);
}

.contracts-page-button{
    border: 1px solid #dedede;
    background-color: #F2F2F2;
    color: #777777;
    font-family: 'Fakt Normal';
    font-size: 15px;
    font-weight: bold;
    border-bottom: 0px;
    border-radius: 7px 7px 0 0;
    height: 45px;
    width: 160px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.contracts-page-button:hover{
    color: #03a9f4;
}

.delete-icon-container{
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 3px;
}

.delete-icon-container:hover{
    border: 2px solid red;
}

/* botão troca de página */

.contract-page-div{
    width: 100%;
    text-align: center;
}

.contract-page-div-container{
    width: 200px;
    display: inline-block;
}

.contract-page-flex{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.change-page-button{
    background-color: #E0E1E2;
    width: 45px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s;
    user-select: none;
}

.change-page-button:hover{
    background-color: #cacaca;
}

.disabled{
    background-color: #f7f7f7;
    cursor: default;
}

.disabled:hover{
    background-color: #f7f7f7;
}

.arrow-left-icon{
    transform: rotate(180deg);
}

#custom-select-status-div{
    position: relative;
    z-index: 999;
    display: none;
    flex-direction: column;
    width: 200px;
    background-color: white;
    border: solid 1px #96C8DA;
    border-radius: 3px;
}

.custom-select-status{
    width: 100%;
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: 0.1s;
    cursor: pointer;
}

.custom-select-status:hover{
    background-color: rgb(241, 241, 241);
}

.contracts-status-color{
    width: 14px;
    height: 14px;
    border-radius: 100px;
    margin: 0px 15px 0px 15px;
}

.custom-select-status-button{
    width: 160px;
    height: 40px;
    min-height: 40px;
    font-size: 17px;
    border-width: 0;
    border-radius: 5px;
    background-color: #E0E1E2;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Fakt Normal';
    cursor: pointer;
    color: rgb(40, 40, 40);
    transition: 0.1s;
}

.no-select{
    user-select: none;
}

.custom-select-status-button:hover{
    background-color: rgba(0, 0, 0, 0.2);
}

.custom-select-status-button-inner{
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius:  5px 0px 0px 5px;
}

.contract-filters-right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 5%;
    margin-top: 15px;
}