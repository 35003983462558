.new-model-main{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.new-model-fields{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
}

.models-bottom-models{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 64vh;
}