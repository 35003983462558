.new-contract-page{
    margin-top: 66px;
    width: auto;
    min-height: 100vh;
    font-family: 'Fakt Normal';
    display: flex;
    flex-direction: column;
}

.new-contract-top{
    width: 100%;
    height: 91px;
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    background-color: white;
}

.new-contract-top-left{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-contract-top-right{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
}

.new-contract-bottom{
    display: flex;
    flex-direction: row;
    height: 81.5vh;
}

.new-contract-bottom-left{
    display: flex;
    flex-direction: column;
    width: 30%;
}

.new-contract-bottom-right{
    display: flex;
    justify-content: center;
    align-items: center;
    width:70%;
    background-color: #525659;
}

.new-contract-return{
    float: left;
}

.new-contract-search-div{
    border-top: 1px solid #e0e6ed;
    border-bottom: 1px solid #e0e6ed;
    height: 60px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.new-contract-templates{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    box-sizing: border-box;
}

.new-contract-template{
    min-height: 45px;
    border-bottom: 1px solid #e0e6ed;
    display: flex;
    align-items: center;
    padding: 8px 30px;
    cursor: pointer;
    transition: 0.2s;
}

.new-contract-template:hover{
    background-color: rgba(233, 233, 233, 0.884);
}

.template-container{
    width: 100%;
    height: 100%;
}

.use-model-button{
    background-color: #03a9f4;
    color: white;
    border: 0px;
    font-size: 18px;
    padding: 12px 60px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s;
}

.use-model-button:hover{
    background-color: #03a8f4c9;
}

.search-model-input{
    outline: none;
    font-size: 20px;
    border: 0px;
    padding: 10px 30px;
    width: 90%;
}

/* drag and drop */
/* drag and drop */

.drop-container {
    border: 2px dashed #ccc;
    border-radius: 5px;
    text-align: center;
    width: 40vw;
    height: 20vh;
    position: relative;
}

#proceedButton{
    border: 0;
    border-radius: 5px;
    width: 40vw;
    height: 6vh;
    background-color: #03a9f4;
    color: white;
    font-size: 18px;
    cursor: pointer;
    transition: 0.1s;
    margin-top: 3vh;
}

#proceedButton:hover{
    background-color: #2bb5ff;
}

.drop-message {
    color: #888;
    font-size: 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.drop-message-row{
    color: #888;
    font-size: 16px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.file-input-label {
    cursor: pointer;
    color: #1397F1;
    font-size: 27px;
    transition: 0.1s;
}

.file-input-label:hover{
    color: #4cc7ff;
}

.select-type-div{
    transition: 0.2s;
}

.select-type-div:hover{
    background-color: rgb(244, 244, 244);
}

#fileInput{
    display: none;
}