.profile-page{
    margin-top: 120px;
    font-family: Arial, Helvetica, sans-serif;
}

.profile-content{
    display: flex;
    justify-content: center;
}

.profile-login-card{
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 200px;
    width: 400px;
    justify-content: center;
}

.profile-register-card{
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 80px;
    width: 400px;
    justify-content: center;
}

.profile-login-card-top{
    color: #6EC1E4;
    font-size: 20px;
    text-align: center;
}

.profile-login-card-fields{
    margin-top: 0px;
    display: flex;
    flex-direction: column;
}

.profile-login-inputs{
    border: 0;
    border-radius: 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 7px 1px;
    height: 50px;
    font-size: 22px;
    padding-left: 15px;
    outline: none;
    width: 383px;
}

.profile-login-inputs-title{
    font-size: 22px;
    margin: 0 0 3px 2px;
    color: #54595f;
}

.profile-login-card-buttons{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.profile-login-buttons{
    border: 0;
    border-radius: 7px;
    background-color: #6EC1E4;
    color: white;
    width: 190px;
    height: 65px;
    font-size: 24px;
    font-weight: 600;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 10px 1px;
    cursor: pointer;
    transition: 0.2s;
}

.profile-login-buttons:hover{
    font-size: 26px;
}