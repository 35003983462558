.modal-content-people {
    background-color: #ffffff;
    margin: 3% auto;
    border: 0px solid #888;
    width: 900px;
    height: 780px;
    /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4); */
    border-radius: 5px 5px 5px 5px;
    text-align: center;
    font-family: 'Fakt Medium';
}

.people-modal-top{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 8%;
    border-bottom: 1px solid #2224265b;
    box-sizing: border-box;
}

.people-modal-top-left{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.people-modal-top-right{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.people-modal-main{
    text-align: left;
    padding: 30px 40px;
    overflow-y: scroll;
    height: 82%;
    box-sizing: border-box;
}

.people-modal-inputs{
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.people-inputs{
    width: 100%;
    height: 40px;
    font-size: 17px;
    border-radius: 3px;
    outline: none;
    border: 1px solid #2224263a;
    margin-top: 3px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    font-family: Arial;
}

.people-modal-inputs-half{
    width: 49%;
}

.people-modal-bottom{
    height: 10%;
    box-sizing: border-box;
    background-color: #F9FAFB;
    border-top: 1px solid #2224265b;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 0px 0px 10px 10px;
}

.people-modal-button{
    width: 110px;
    height: 50px;
    font-size: 18px;
    border: 0px;
    border-radius: 5px;
    background-color: #E0E1E2;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    transition: 0.1s;
}

.people-modal-button:hover{
    color: rgba(0, 0, 0, 0.795);
    background-color: #c4c4c4;
}

.people-modal-button2{
    width: 110px;
    height: 50px;
    font-size: 18px;
    border: 0px;
    border-radius: 5px;
    margin: 0px 20px;
    background-color: #03a9f4;
    color: white;
    cursor: pointer;
    transition: 0.1s;
}

.people-modal-button2:hover{
    background-color: #03a8f4b4;
}