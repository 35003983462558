.account-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 100px;
}

#account-initials{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #03a9f4;
    width: 150px;
    height: 150px;
    border-radius: 200px;
    font-size: 50px;
    font-family: 'Fakt Medium';
    font-weight: bold;
    color: white;
}

.account-divs{
    width: 50%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.account-divs-1{
    width: 42%;
    justify-content: flex-end;
    margin-right: 6px;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #262626;
}

.account-divs-2{
    width: 50%;
    text-align: left;
    margin-left: 6px;
}

.account-inputs{
    font-size: 18px;
    width: 200px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    padding: 10px;
    border-radius: 5px;
    outline: none;
}

.account-inputs:focus{
    border-color: #8dc0ff;
}

.save-button-div{
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}