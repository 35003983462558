.header{
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    height: 66px;
    justify-content: space-between;
    z-index: 999;
    font-family: 'Fakt Medium';
    border-bottom: 1px solid #cccccc;
    background-color: white;
}

.header-left{
    margin-left: 20px;
    width: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-right{
    width: 500px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.header-options{
    color: #777777;
    cursor: pointer;
    text-decoration: none;
}

.header-options:visited{
    color: #777777;
    text-decoration: none;
}

.header-options:hover{
    color: #03a9f4;
}

