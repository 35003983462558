/* PAGE LEFT SECTION */
/* PAGE LEFT SECTION */

.page{
    margin-top: 66px;
    width: auto;
    min-height: 100vh;
    font-family: 'Fakt Normal';
}

#wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.637);
    position: fixed;
    top: 0;
    left: 0;
}

#loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #03a9f4; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.contractPage{
    width: auto;
    min-height: 100vh;
    font-family: 'Fakt Normal';
}

#loader-warn{
    font-family: 'Fakt Normal';
    font-size: 20px;
    margin-top: 10px;
    display: none;
}

.contract-page-left{
    flex-grow: 1;
    margin-right: 30rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.pdf-container{
    width: 100%;
    height: 100vh;
}

embed:focus {
    outline: none;
}

.contract-page-top{
    display: flex;
    flex-direction: row;
    background-color: #efefef;
    height: 40px;
}

.contract-page-top-left{
    width: 20%;
    display: flex;
    align-items: center;
}

.contract-page-top-center{
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}



.contract-page-top-right{
    width: 20%;
    display: flex;
    justify-content: right;
    align-items: center;
}

.contract-return-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-left: 40px;
}

#contract-status-color{
    width: 14px;
    height: 14px;
    border-radius: 100px;
    margin-top: 1px;
}

/* PAGE RIGHT SECTION */
/* PAGE RIGHT SECTION */

.contract-page-right{
    position: absolute;
    width: 30rem;
    height: 100vh;
    background-color: white;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
}

.contract-page-right-items{
    border-left: 1px #C2C2C2 solid;
    border-right: 1px #C2C2C2 solid;
    display: flex;
    flex-direction: column;
    background-color: #fafafb;
    height: 100vh;
    width: 18%;
    z-index: 100;
}

.contract-page-right-fields{
    width: 82%;
    display: flex;
    background-color: #fafafb;
}

.fields-item-title{
    display: flex;
    align-items: center;
    padding-left: 15px;
    color: #262626;
    height: 50px;
    width: 100%;
    box-shadow: 0px -10px 15px rgba(0,0,0,0.5);
}

.fields-item-title > h3 {
    color: #262626;
}

.contract-menu-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 75px;
    transition: 0.1s;
    box-sizing: border-box;
}

.contract-menu-item:hover{
    background-color: #ebebeb;
    cursor: pointer;
}

.fields-div-container{
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.contract-menu-item-icon{
    height: 50%;
    display: flex;
    align-items: flex-end;
}

.contract-menu-item-title{
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.contract-menu-item-title > p{
    margin: 3px 0 0 0;
    font-size: 13px;
    color: #777777;
    text-align: center;
}

.svg-settings{
    filter: invert(44%) sepia(16%) saturate(0%) hue-rotate(144deg) brightness(102%) contrast(98%);
}

.svg-settings-white{
    filter: brightness(0) invert(1);
}

/* FIELDS SECTION */
/* FIELDS SECTION */

.fields-item-fields{
    padding-top: 30px;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
}

.fields-item-fields-save{
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.save-contract-button{
    width: 85%;
    height: 50px;
    border: none;
    border-radius: 5px;
    font-size: 17px;
    font-family: 'Fakt Medium';
    color: white;
    background-color: #03a9f4;
    cursor: pointer;
    transition: 0.2s;
}

.save-contract-button:hover{
    background-color: #03a8f4bb;
}

.fields-item-field{
    margin-bottom: 30px;
    width: 332px;
}

.field-title{
    font-size: 15px;
    margin-bottom: 5px;
    font-family: 'Fakt Medium';
    color: #242424;
}

.field-input{
    font-size: 16px;
    border: 1px #77777736 solid;
    width: 300px;
    outline: none;
    padding: 10px 15px;
    border-radius: 5px;
    transition: 0.2s;
}

.field-input-shares{
    font-size: 15px;
    border: none;
    width: 300px;
    outline: none;
    padding: 8px 8px;
    border-radius: 5px;
    transition: 0.2s;
    background-color: #e8e8e8;
    cursor: pointer;
}

.field-input-shares select {
    display: none; /*hide original SELECT element: */
}

.field-input-shares:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
  }

.field-input:hover{
    border-color: #7777779d;
}

.field-input:focus{
    border-color: #8dc0ff;
}

.add-shares-div{
    padding-top: 10px;
}

.add-shares-div:hover{
    cursor: pointer;
    color: #d8f3ff;
}

.add-shares-div > p{
    font-size: 18px;
    color: #4cc7ff;
    font-family: 'Fakt Medium';
}

/* SHARES */
/* SHARES */

.shares-div{
    flex-direction: column;
    justify-content: center;
    overflow-y: auto;
}

.share-div{
    width: 360px;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 5px;
    background-color: white;
    margin-bottom: 20px;
}

.share-div-top{
    margin: 12px 12px 8px 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.share-div-middle{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0px 12px;
}

.share-div-bottom{
    display: flex;
    flex-direction: column;
    margin: 12px 12px 12px 12px;
}

.share-div-bottom-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 14px;
}

.share-info{
    color: #777777;
    margin-bottom: 5px;
}

.div-rep{
    background-color: #E8E8E8;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 14px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-family: 'Fakt Medium';
    font-weight: bold;
}

.div-rep-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 6px 10px 4px 10px;
}

.div-rep-bottom{
    display: flex;
    flex-direction: column;
    margin: 0px 10px 10px 38px;
}

.signature-send-button{
    width: 70%;
    height: 50px;
    border: none;
    border-radius: 5px;
    font-size: 17px;
    font-family: 'Fakt Medium';
    color: white;
    background-color: #03a9f4;
    cursor: pointer;
    text-align: center;
}

.div-rep-none{
    display: flex;
    flex-direction: row;
    cursor: pointer;
    border: #777777 dashed 2px;
    border-radius: 5px;
    padding: 7px;
    margin-bottom: 12px;
    color: #777777;
    font-family: 'Fakt Medium';
}

.send-signatures{
    width: 100%;
    position: absolute;
    bottom: 20px;
    display: flex;
    justify-content: center;
}

/* MODAL */
/* MODAL */

.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 200;
}

.modal-content {
    background-color: #fafafb;
    margin: 10% auto;
    padding: 20px;
    border: 0px solid #888;
    width: 500px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    text-align: center;
}

.close {
    color: #aaa;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover {
    color: black;
}

.modal-shares-top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
}

.modal-shares-middle{
    height: 200px;
    width: 398px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid #2224262d;
    border-radius: 3px;
}

.modal-shares-middle-item{
    display: flex;
    flex-direction: row;
    height: 60px;
    cursor: pointer;
    transition: 0.1s;
    padding: 10px 0;
}

.modal-shares-middle-item:hover{
    background-color: rgb(248, 248, 248);
}

.shares-middle-item-left{
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shares-middle-item-right{
    display: flex;
    flex-direction: column;
    width: 85%;
    align-items: flex-start;
    justify-content: space-evenly;
}

.shares-middle-item-right > p{
    color: #777777;
}

.modal-shares-bottom{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    margin-bottom: 10px;
}

.shares-search{
    width: 400px;
}

.shares-search-input{
    padding: 15px 15px;
    width: 100%;
    font-size: 20px;
    outline: none;
    border-radius: 5px;
    border: 1px solid #E0E6ED;
    box-sizing: border-box;
}

.delete-modal-top{
    border-bottom: 1px solid #777777;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15%;
}

.delete-modal-middle{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 45%;
}

.delete-modal-bottom{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 40%;
}

.modal-button{
    width: 230px;
    height: 45px;
    border: 0px;
    font-size: 16px;
    color: white;
    background-color: #03a9f4;
    border-radius: 5px;
    transition: 0.2s;
    cursor: pointer;
    font-size: 18px;
}

.modal-button:hover{
    background-color: #03a8f4c2;
}

.modal-button-return{
    width: 230px;
    height: 45px;
    border: 0px;
    font-size: 16px;
    color: #03a9f4;
    border-radius: 5px;
    transition: 0.2s;
    cursor: pointer;
    background-color: #fafafb;
    font-weight: bold;
    font-size: 18px;
}

.elipse-nowrap{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 240px;
}

/* change model screen */
/* change model screen */

.change-model-screen {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 200;
}


.share-menu:hover{
    background-color: rgba(128, 128, 128, 0.103);
}

.pStatus{
    margin-right: 40px;
    margin-left: 10px;
    color: #777777;
}

/* responsivo para tablet */
/* responsivo para tablet */

@media(max-width: 1200px){
    .contract-page-left{
        width: 40%;
    }
    .contract-page-right{
        width: 60%;
        height: 100%;
    }
    .contract-return-button{
        margin-left: 20px;
    }
    .pStatus{
        margin-right: 10px;
    }
}